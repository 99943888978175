/* eslint-disable react/jsx-indent */
import React, { useMemo } from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import { useIntl } from 'gatsby-plugin-react-intl'
import 'react-vertical-timeline-component/style.min.css'
// import Bounce from 'react-reveal/Bounce'
import Layout from '../components/Layout'
import trackAndFieldIcon from '../img/trackAndFieldIcon.png'

const contentAneta = [
  {
    year: 1998,
    pl:
      'Światowe Igrzyska Młodzieży w Moskwie, srebrny medal w biegu na 400m i złoty w sztafecie 4x100m',
    eng:
      'World Youth Games in Moscow, silver medal in 400m and gold in 4x100m relay',
  },
  {
    year: 1999,
    pl: 'Mistrzostwa Europy Juniorów w Rydze, brązowy medal w sztafecie 4x400m',
    eng: 'European Junior Championships in Riga, bronze medal in 4x400m relay',
  },
  {
    year: 2000,
    pl:
      'Mistrzostwa Świata Juniorów w Santiago de Chile, srebrny medal w biegu na 400m (ustanowienie rekordu Polski wynikiem 52.78, który przetrwał do 2012 roku)',
    eng:
      'World Junior Championships in Santiago de Chile, silver medal in 400m (setting the Polish Junior record with the result of 52.78 until 2012)',
  },
  {
    year: 2001,
    pl:
      'Młodzieżowe Mistrzostwa Europy w Amsterdamie, brązowy medal w biegu na 400m i srebrny medal w sztafecie 4x400m',
    eng:
      'European U23 Championships in Amsterdam, bronze medal in 400m and silver medal in 4x400m relay',
  },
  {
    year: 2002,
    pl: 'Halowe Mistrzostwa Europy w Wiedniu, srebrny medal w sztafecie 4x400m',
    eng:
      'European Indoor Championships in Vienna, silver medal in 4x400m relay',
  },
  {
    year: 2016,
    pl:
      'Halowe Mistrzostwa Europy Masters w Anconie, srebrny medal w biegu na 800m (kat. W35)',
    eng:
      'European Masters Indoor Championships in Ancona, silver medal in 800m (W35)',
  },
  {
    year: 2016,
    pl:
      'Mistrzostwa Świata Masters w Perth, złoty medal w biegu na 800m (kat. W35)',
    eng: 'World Masters Championships in Perth, gold medal in 800m (W35)',
  },
  {
    year: 2017,
    pl:
      'Halowe Mistrzostwa Świata Masters w Daegu, złote medale w biegach na 400m i 800m oraz srebrny medal w sztafecie 4x200m (kat. W35)',
    eng:
      'World Masters Indoor Championships in Daegu, gold medals in 400m and 800m and silver medal in 4x200m relay (W35)',
  },
  {
    year: 2017,
    pl:
      'Mistrzostwa Europy Masters w Aarhus, złote medale w biegach na 400m i 800m oraz srebrne medale w sztafetach 4x100m i 4x400m (wyrównanie rekordu ME na 400m w kat. W35 - 55.14).',
    eng:
      'European Masters Championships in Aarhus, gold medals in 400m and 800m and silver medals in 4x100m and 4x400m relay (alignment the European Championships record of 400m in W35 – 55.14) (W35)',
  },
  {
    year: 2019,
    pl:
      'Halowe Mistrzostwa Świata w Toruniu, złote medale w biegach na 400m i 800m oraz złoty i halowy rekord świata w sztafecie 4x200m (kat. W35)',
    eng:
      'World Masters Indoor Championships in Toruń, gold medal in 400m and 800m and gold and world indor record in 4x200m relay (W35)',
  },
  {
    year: 2019,
    pl:
      'Mistrzostwa Europy w Wenecji, złote medale w biegach na 400m i 800m (kat. W35)',
    eng:
      'European Masters Championships in Venice, gold medals in 400m and 800m (W35)',
  },
]

function AboutAneta() {
  const iconRun = <img src={trackAndFieldIcon} width="80%" alt="" />

  const intl = useIntl()

  const elements = useMemo(
    () => (
      <>
        {contentAneta.map((yearContent, i) => {
          return (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date={yearContent.year}
              iconStyle={{
                background: 'rgb(33, 150, 243)',
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon={iconRun}
              visible={false}
              key={`timeline-el-${i}`}
            >
              <p>{intl.locale === 'pl' ? yearContent.pl : yearContent.eng}</p>
            </VerticalTimelineElement>
          )
        })}
      </>
    ),
    []
  )

  return (
    <Layout>
      <section className="section-bubble4">
        <div className="container">
          <h1
            className="title rainbow-underline p-3"
            style={{ paddingTop: '1em' }}
          >
            {intl.messages.aboutMe}
          </h1>

          <VerticalTimeline animate>
            {elements}

            <VerticalTimelineElement
              iconStyle={{
                background: 'rgb(16, 204, 82)',
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon={iconRun}
              visible={false}
            />
          </VerticalTimeline>
        </div>
      </section>
    </Layout>
  )
}

export default AboutAneta
